import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import Carousel from "react-material-ui-carousel";

import Kontaktformular from "./kontaktformular"

const ImageBackground = styled.div`
background-image: ${(props) => props.url};
height: 100%;
background-color: #5D768B;
background-position: center;
background-size: cover;
border: 1px solid #fff;
display: flex;
align-items: center;
justify-content: center;
}
`;

const useStyles = makeStyles((theme) => ({
  kontaktcontainer: {
    height: "70vh",
    minHeight: "550px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "90vh",
      minHeight: "850px",
      maxHeight: "950px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      minHeight: "950px",
      maxHeight: "950px",
    },

  },
  testimonial: {
    padding: "0px 50px",
    maxHeight: "600px",

    "& button": {
      display: "none",
    },
    "& p": {
      textAlign: "center",
    },
    "& .small": {
      opacity: "0.5",
      fontSize: "80%",
    },
  
  
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      height: " 40%",
    },

  },

  form: {
    padding: "0px 80px",
    maxHeight: "600px",
    display: "flex",
    alignSelf: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "0px 80px",
      height: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 30px",
    },
  },
}));

function KontaktComponent(props) {
  const classes = useStyles();

  return (
    <div >
      <Grid className={classes.kontaktcontainer} container spacing={0}>
        <Grid item xs={12} sm={12} md={6} className={classes.image}>
          <ImageBackground url={props.imageUrl}>
            <Carousel autoPlay className={classes.testimonial} interval={12000}> 
              {props.testimonial.map((item) => (
                <div key={item.name}>
                  <p className="font__white">
                    <i>{item.description}</i>
                  </p>
                  <p className="uppercase font__white small">{item.name}</p>
                </div>
              ))}
            </Carousel>
          </ImageBackground>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.form} id="kontakt">
          <Kontaktformular />
        </Grid>
      </Grid>
    </div>
  );
}

export default KontaktComponent;
