import React from "react";


const ArrowRight = () => {
    return (
        <svg width="82px" height="10px" viewBox="0 0 82 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-553.000000, -2907.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path id="Line-3" d="M634.5,2912.25 L625.5,2907.75 L625.5,2916.75 L634.5,2912.25 Z M554,2912.75 L626,2912.75 L626.5,2912.75 L626.5,2911.75 L626,2911.75 L554,2911.75 L553.5,2911.75 L553.5,2912.75 L554,2912.75 Z"></path>
            </g>
        </g>
    </svg>
    )}
    
    export default ArrowRight
    