import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Zoom from '@material-ui/core/Zoom';

// Styled Components
import ContentWrapper from "../components/layout/contentWrapper";

// Components
import Layout from "../components/layout/layout";
import SeminarBlock from "../components/seminarBlock";
import KontaktComponent from "../components/kontaktComponent";
import ZweiSpalter from "../components/homeZweispalter";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Data
import backgroundImage from "../images/Dialog Aktiv Ueber mich 2.jpg";



const myContent = "Persönliche Stärken erkennen und fördern, individuelle Lernfelder ansprechen und genau dort mit unseren Seminar- und Trainingsmaßnahmen ansetzen – das ist unser Ziel. \n Wir wenden Methoden an – doch hinter diesen Methoden steht der Mensch und dieser steht für uns immer im Mittelpunkt. \n „Der Weg in die Köpfe führt durch die Herzen.“"
const myQuote = "„Sage es mir - und ich werde es vergessen. \n Zeige es mir - und ich werde mich erinnern. \n Beteilige mich - und ich werde es verstehen.“ "

const ueberMich = "Dieser Leitsatz bestimmt mein konkretes Handeln bei der Begleitung von Menschen in Seminaren und als Coach.\n  Fachliche Kompetenz, ein wertschätzendes Miteinander und Spaß an den zu vermittelnden Themen sind tragende Säulen meiner Tätigkeit. Alles beginnt mit einer Analyse zum konkreten Trainingsbedarf – danach erhalten Sie maßgeschneiderte Schulungskonzepte, die bedarfsorientiert und modular aufgebaut sind. "
const meta = "Persönliche Stärken erkennen und fördern, individuelle Lernfelder ansprechen und genau dort mit unseren Seminar- und Trainingsmaßnahmen ansetzen – das ist unser Ziel."

const StartHero = styled.div`
height: 100vh;
width: 100vw;
display: flex;
justify-content: center;
align-items: center;
text-align: center;


p {
  letter-spacing: 8px;
}

h1 {
  @media (min-width: 960px) {
    letter-spacing: 10px;
    font-size: 400%;
  }
  @media (min-width: 768px) and (max-width: 960px) {
    letter-spacing: 8px;
    font-size: 300%;

  }
  @media (max-width: 767px) {
    letter-spacing: 4px;
    font-size: 190%;
    letter-spacing: 4px;
    margin: 0px 20px;
    
  }
}

button {
  position: absolute;
    top: 90vh;
    background: transparent;
    border: 0;
    @media (max-width: 960px) {
      top: 83vh;
  }

}
`

const checked = true;


class Home extends Component {

  
  render() {
    return (
      <Layout pageTitle="Dialog Aktiv - Seminare, Training, Coaching" description={meta}>

        <StartHero
          className="hero--background"
          alt="Dialog Aktiv Startseite Background"
          id="back-to-top-anchor"
        >
          <div >
          <h1>Seminare - Training - Coaching</h1>
          <h2>Unsere Seminar- und Trainingsthemen bieten wir auch digital an.</h2>
          </div>
          <Zoom in={checked}  timeout={2000} >

          <button>
            <a href="/#credo">
          <ExpandMoreIcon style={{ fontSize: 40 }} />
          </a>
          </button>
          </Zoom>

        </StartHero>

        <ContentWrapper>
        <div id="credo"> 
      <ZweiSpalter title={"Unser Credo"} content={myContent} quote={myQuote} author={"Lao Tse"} imageUrl={""} />
      </div>
        <SeminarBlock
          seminare={[
            "Erfolgreiche Kommunikation",
            "Kommunikation am Telefon",
            "Professionelles Zeit- und Selbstmanagement",
            "Lösungsorientiertes Konfliktmanagement",
            "Spezielle Seminarthemen",
            "Coaching und Training",
          ]}
          slug={[
            "erfolgreiche-kommunikation",
            "kommunikation-am-telefon",
            "professionelles-zeit-und-selbstmanagement",
            "lösungsorientiertes-konfliktmanagement",
            "spezielle-seminarthemen",
            "coaching-und-training",
          ]}
        />
              <ZweiSpalter title={"„Der Weg in die Köpfe führt durch die Herzen.“"} content={ueberMich} quote="" imageUrl={`url("${backgroundImage}")`}/>

        <KontaktComponent
          testimonial={[
            {
              name: "(Sabine Ergler, Teamleiterin Debitorenbuchhaltung, FRONERI Schöller GmbH)",
              description: "„Kristina Lahl kann sich durch ihr sehr gutes Einfühlungsvermögen und ihr umfangreiches Fachwissen sehr gut und schnell auf die Seminarteilnehmer und Situationen einstellen, so dass sie auch jeden Skeptiker von Seminaren oder Themen auf ihre Seite zieht und überzeugt. Ihr Erfolg spricht für sie!“",
            },
            {
              name: "(Stefan  Kaupp, Kunden-Service-Center, Kreissparkasse Esslingen-Nürtingen)",
              description: "„Wir arbeiten seit über 15 Jahren vertrauensvoll mit Kristina Lahl zusammen. Sie ist sehr einfühlsam im Coaching und geht individuell auf die Teilnehmer ein. Ihr Feedback ist nachvollziehbar und regt zur Umsetzung an. Ihre Seminare sind praxisorientiert, unterhaltsam und geprägt von ihrer großen Erfahrung.“",
            },
            {
              name: "Andreas Berndt, Verkaufsdirektor national, Geschäftsbereich Kaffee, Melitta Europa GmbH & Co. KG",
              description: "„Schon seit mehreren Jahren begleitet uns Frau Lahl im Kontext der Personalentwicklung für unseren Außendienst. Von der Konzepterstellung bis zur Durchführung versteht sie es,  mit ihrer freundlichen und kompetenten Art auf alle Beteiligten einzugehen.  Das Feedback der Teilnehmer ist durchweg sehr positiv, da die Seminarinhalte verständlich, nachvollziehbar und unter Berücksichtigung der Bedürfnisse der Teilnehmer vermittelt werden. Kurzum: Empfehlenswert.“"
            }
          ]}
        />
        </ContentWrapper>
        
      </Layout>
    );
  }
}

export default Home;
