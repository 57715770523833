import React, { Component } from "react";
import styled from "styled-components";

import axios from "axios";


const FormWrapper = styled.div`
  label {
    display: none;
  }
  input {
    width: 80%;
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    color: #606060;
    border: 1px solid #a3a3a3;
    border-radius: 0px;
    appearance: none;
    font-size: 100%;
  }

  textarea {
    width: 80%;
    height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    color: #606060;
    resize: none;
    border: 1px solid #a3a3a3;
    border-radius: 0px;
    font-family: "Jost";
  }
  .senden {
    width: 180px !important;
    height: 50px !important;
    background-color: #A0040C !important;
    border: 0 !important;
    borderRadius: 0px !important;
    boxShadow: 0 !important;
    color: #fff ;
    letter-spacing: 6px ;
    text-transform: uppercase ;
    cursor: pointer ;
    appearance: none;
    @media(max-width: 600px) {
      width: 160px !important;
    height: 40px !important;
    }

  }
`;

class Kontaktformular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      nachricht: "",
      mailSent: false,
      error: null,
      fuellen: false,
    };
  }

  handleFormSubmit = e => {
    e.preventDefault();

    if(this.state.name === "" || this.state.email === "" || this.state.nachricht === ""){
      this.setState({
        mailSent: false,
        error: false,
        fuellen: true,
      })
    }else{

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
    .then(result => {
      if (result.data.sent) {
        this.setState({
          mailSent: result.data.sent,
          name: "", 
          email: "", 
          nachricht: "",
          fuellen: false,
        });
        this.setState({ error: false });
      } else {
        this.setState({ error: true });
      }
    })
    .catch(error => this.setState({ error: error.message }));
  }
  };

  render() {
    return (

      <FormWrapper>
        <h2 className="font__red uppercase">Fragen?</h2>
        <p>Sie haben Interesse an unseren Seminaren?</p>
        <p>Nehmen Sie gerne schnell und einfach mit uns Kontakt auf.</p>

        <form action="#">
          <label>Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name.."
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />

          <label>Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />

          <label>Nachricht</label>
          <textarea
            id="nachricht"
            name="nachricht"
            placeholder="Nachricht..."
            value={this.state.nachricht}
            onChange={(e) => this.setState({ nachricht: e.target.value })}
          ></textarea>
        <input className="senden" type="submit" onClick={e => this.handleFormSubmit(e)} value="Senden"/>
        
        </form>
        <div>
              {this.state.mailSent && <div className="success"><h4>Vielen Dank für Ihre Anfrage. Wir beantworten diese schnellstmöglich.</h4></div>}
              {this.state.error && <div className="error"><h4>Da ist etwas schief gelaufen, bitte versuchen Sie es erneut.</h4></div>}
              {this.state.fuellen && <div className="error"><h4>Bitte füllen Sie alle Felder aus.</h4></div>}

            </div>
      </FormWrapper>
    );
  }
}

export default Kontaktformular;
