import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Layout from "../components/layout/layout";
import ContentWrapper from "../components/layout/contentWrapper";
import { Link } from "react-router-dom";

const TextDiv = styled.div`
  padding: 80px;


  @media (max-width: 600px) {
    padding: 30px;
  }
`;

const HeaderDiv = styled.div`
  background-color: #5d768b;
  height: 30vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;


class NotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}

  render() {
    return (
      <Layout>
        <HeaderDiv>
          <h1 className="uppercase">404 Error. </h1>
        </HeaderDiv>
        <ContentWrapper>
          <TextDiv>
            <br />
            <h2 className="uppercase font__red">Diese Seite konnte nicht gefunden werden.</h2>
            <h2 className="font__grey">Versuchen Sie es doch einfach hier: </h2>
            <Link to="/"><h4>https://dialog-aktiv.de/</h4></Link>
            <div id="kontakt"></div>
          </TextDiv>
        </ContentWrapper>
      </Layout>
    );
  }
}

export default NotFound;
