
import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom";


const StyledButtonFooter = styled.button`
width: 180px;
height: 50px;
background-color: #A0040C;
border: 0;
@media(max-width: 600px) {
  width: 160px;
height: 40px;
}
p {
  margin: 0px;
  text-align: center;
  letter-spacing: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
`

function ButtonRedFooter(props) {
  return ( 
    <>
      <StyledButtonFooter>
        <a href={props.link}><p className="font__white uppercase">{props.children}</p></a>
      </StyledButtonFooter>

  </>
  )
}


export default ButtonRedFooter