import styled from "styled-components";


const ContentWrapper = styled.div`
width: 100%;
max-width: 1440px;
margin: 0px auto;
background-color: #fafafa;
`

export default ContentWrapper