import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

// Data
import menudata from "../../data/menu.json";
import Logo from "../../images/Logo_dialog-aktiv.png";




const useStyles = makeStyles((theme) => ({
  logowrapper: {
    position: "absolute",
    top: "20px",
    left: "20px",
    width: "100px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  list: {
    width: "40vw",
    [theme.breakpoints.down("xs")]: {
      width: "70vw",
    },
  },
  fullList: {
    width: "auto",
  },
  menu: {
    position: "absolute",
    right: "0px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  open: {
    color: "#A0040C",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    justifyContent: "flex-end",
    textAlign: "end",
  }
}));

export default function MobileMenu() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={menudata.Menu[1].slug}  className={classes.button}>
        <Link className="uppercase"  to={menudata.Menu[1].slug}><b>{menudata.Menu[1].name}</b></Link>
        </ListItem>
        <ListItem button key={menudata.Menu[0].slug}  className={classes.button}>
          <a className="uppercase" href="/#seminare" ><b>{menudata.Menu[0]}</b></a>
        </ListItem>

        {menudata.Submenu.map((item) => (
          <ListItem button key={item.name} className={classes.button} style={{ borderBottom: "1px solid #fff"}}>
            <Link to={item.slug}>{item.name}</Link>
          </ListItem>
        ))}

        <ListItem button key={menudata.Menu[2].slug} className={classes.button}>
          <Link className="uppercase"  to={menudata.Menu[2].slug}><b>{menudata.Menu[2].name}</b></Link>
        </ListItem>
        <ListItem button key={menudata.Menu[3].slug} className={classes.button}>
          <a className="uppercase"  href="#kontakt"><b>{menudata.Menu[3].name}</b></a>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
    <Link to="/">
    <img src={Logo} alt="Dialog Aktiv Logo" className={classes.logowrapper}/>
  </Link>
    <div className={classes.menu}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            aria-label="open drawer"
            onClick={toggleDrawer(anchor, true)}
            className={classes.open}
          >
            <MenuIcon fontSize="large" />
          </IconButton>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className={classes.drawer}
          >
            <IconButton
              style={{ color: "#fff", justifyContent: "flex-end" }}
              aria-label="open drawer"
              onClick={toggleDrawer(anchor, false)}
            >
              <CloseIcon />
            </IconButton>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
    </div>
  );
}
