import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";            
import Kommunikation from "./pages/Erfolgreiche Kommunikation";
import Telefon from "./pages/Kommunikation am Telefon";
import Konfliktmanagement from "./pages/Konfliktmanagement";
import Selbstmanagement from "./pages/zeitmanagement"
import SpezielleSeminare from "./pages/spezielleSeminare"
import Training from "./pages/training"
import DialogAktiv from "./pages/UeberUns"
import NotFound from "./pages/404"
import "./App.css";



function App() {
  return (
    <div className="App">
      <Router>
        <div className="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/datenschutz" component={Datenschutz} />
            <Route exact path="/erfolgreiche-kommunikation" component={Kommunikation} />
            <Route exact path="/kommunikation-am-telefon" component={Telefon} />
            <Route exact path="/lösungsorientiertes-konfliktmanagement" component={Konfliktmanagement} />
            <Route exact path="/professionelles-zeit-und-selbstmanagement" component={Selbstmanagement} />
            <Route exact path="/spezielle-seminarthemen" component={SpezielleSeminare} />
            <Route exact path="/coaching-und-training" component={Training} />
            <Route exact path="/ueber-uns" component={DialogAktiv} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
