import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import ArrowRight from "../components/arrowRight";

import "../App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    borderRadius: 0,
    height: "300px",
    boxShadow: "none",
    border: "1px solid #fff",
    display: "flex",
    alignItems: "center",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      height: "240px",
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "180px",
      padding: "0px",
    },
  },
}));

function SeminarBlock(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} id="seminare">
      <Grid container spacing={0}>
        <Grid item xs={6} sm={4} className="seminarblock">
          <Link
            to={props.slug[0]}
            style={{
              pointerEvents: props.slug[0] === "" && "none",
              cursor: props.slug[0] === "" && "default",
            }}
          >
            <Card
              className={classes.card}
              style={{ backgroundColor: "#9A9A9A" }}
            >
              <CardContent>
                <h3
                  style={{
                    fontSize:
                      props.seminare[0] === "Weitere Seminare" && "230%",
                    textAlign:
                      props.seminare[0] === "Weitere Seminare" && "center",
                  }}
                >
                  {props.seminare[0]}
                </h3>
                <span
                  style={{
                    display: props.seminare[0] === "Weitere Seminare" && "none",
                  }}
                >
                  <ArrowRight />
                </span>
              </CardContent>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={6} sm={4} className="seminarblock" >
          <Link to={props.slug[1]}>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#5D768B" }}
            >
              <CardContent>
                <h3>{props.seminare[1]}</h3>
                <ArrowRight />
              </CardContent>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={6} sm={4} className="seminarblock">
          <Link to={props.slug[2]}>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#E1E1E1" }}
            >
              <CardContent>
                <h3 className="font__grey">{props.seminare[2]}</h3>
                <span className="arrow__grey">
                  <ArrowRight />
                </span>
              </CardContent>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={6} sm={4} className="seminarblock">
          <Link to={props.slug[3]}>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#A0040C" }}
            >
              <CardContent>
                <h3>{props.seminare[3]}</h3>
                <ArrowRight />
              </CardContent>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={6} sm={4} className="seminarblock">
          <Link to={props.slug[4]}>
            <Card className={classes.card}>
              <CardContent>
                <h3 className="font__grey">{props.seminare[4]}</h3>
                <span className="arrow__grey">
                  <ArrowRight />
                </span>
              </CardContent>
            </Card>
          </Link>
        </Grid>

        <Grid item xs={6} sm={4} className="seminarblock">
          <Link to={props.slug[5]}>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#9A9A9A" }}
            >
              <CardContent>
                <h3>{props.seminare[5]}</h3>
                <ArrowRight />
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default SeminarBlock;
