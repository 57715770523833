import React from "react";

const Plus = () => {
  return (
    <svg
      width="120px"
      height="120px"
      viewBox="0 0 120 120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Kommunikation-am-Telefon"
          transform="translate(-1145.000000, -1934.000000)"
          fill-rule="nonzero"
          stroke="#FFFFFF"
          stroke-width="5"
        >
          <g id="Group" transform="translate(1145.000000, 1934.000000)">
            <path d="M0,59.25 L120,59.25" id="Path-3"></path>
            <path d="M60.25,120 L60.25,0" id="Path-4"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Plus;
