import React, { Component } from "react";
import "../App.css";

// Styled Components
import ContentWrapper from "../components/layout/contentWrapper";

// Components
import Layout from "../components/layout/layout";
import AkkordeonTraining from "../components/akkordeonTraining";
import SeminarBlock from "../components/seminarBlock";
import HeroComponent from "../components/heroComponent";
import KontaktComponent from "../components/kontaktComponent";

// Data
import dataInput from "../data/training.json";
import fragenImage from "../images/Training und Coaching.jpg";
import backgroundImage from "../images/Training und Coaching Header.jpg";



class Telefon extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}
  render() {
    return (
      <Layout pageTitle={dataInput.Hero.title} description={dataInput.Hero.metaDescription}>
        <HeroComponent
          imageUrl={`url("${backgroundImage}")`}
          title={dataInput.Hero.title}
          zitat={dataInput.Hero.zitat}
          autor={dataInput.Hero.autor}
          background={"#A0040C"}
        />
        <ContentWrapper>
          <AkkordeonTraining data={dataInput} />

          <SeminarBlock
            seminare={[
              "Weitere Seminare",
              "Erfolgreiche Kommunikation",
              "Kommunikation am Telefon",
              "Professionelles Zeit- und Selbstmanagement",
              "Lösungsorientiertes Konfliktmanagement",
              "Spezielle Seminarthemen",
            ]}
            slug={[
              "",
              "erfolgreiche-kommunikation",
              "kommunikation-am-telefon",
              "professionelles-zeit-und-selbstmanagement",
              "lösungsorientiertes-konfliktmanagement",
              "spezielle-seminarthemen",
            ]}
          />
          <KontaktComponent
            imageUrl={`url("${fragenImage}")`}
            testimonial={[]}
          />
        </ContentWrapper>
      </Layout>
    );
  }
}

export default Telefon;
