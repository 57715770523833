import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import data from "../data/ueberuns.json"



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  ZweiSpalterContainer: {
    height: "80vh",
    minHeight: "550px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
        height: "100vh",
        minHeight: "900px",
        maxHeight: "900px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "100vh",
        minHeight: "950px",
        maxHeight: "950px",
      },

  },

  left: {
    backgroundColor: "#E1E1E1",
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    padding: "60px 80px",
    minHeight: "550px",
    maxHeight: "600px",
    height: "80vh",

    [theme.breakpoints.down("md")]: {
      padding: "40px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      maxHeight: "unset",
      height: "45%",
    },
      [theme.breakpoints.down("xs")]: {
        padding: "20px 30px",
      },
  },
  right: {
    backgroundColor: "#A0040C",
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    padding: "60px 80px",
    height: "80vh",

    minHeight: "550px",
    maxHeight: "600px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      maxHeight: "unset",
      height: "55%",
    },
      [theme.breakpoints.down("xs")]: {
        padding: "20px 30px",
        maxHeight: "1000px",
        
      },
  }
}));

function UeberZweispalter(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.ZweiSpalterContainer} container spacing={0}>
        <Grid className={classes.left} item xs={12} sm={12} md={6}>
          <div>
            <h3 className="font__red">Beruflicher Werdegang</h3>
            {data.Werdegang.map((item) => {
              return( <ul><li>{item}</li></ul>)
            
            })}
          </div>
        </Grid>
        <Grid className={classes.right} item xs={12} sm={12} md={6}>
          <div>
             <h3>Aus- und Weiterbildungen</h3>
             {data.Weiterbildung.map((item) => {
              return( <ul><li className="font__white">{item}</li></ul>)
            
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default UeberZweispalter;
