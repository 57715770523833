import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../images/Logo_dialog-aktiv.png";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";


// Data
import menudata from "../../data/menu.json";

const LogoWrapper = styled.img`
  position: absolute;
  top: 20px;
  left: 30px;
`;

const NavbarWrapper = styled.div`
position: absolute;
`;

const MenuWrapper = styled.div`
    width: 50vw;
    float: right;
    margin-left: 50vw;
    display: flex;
    justify-content: space-around;

}
  button {
    margin: 20px 0;
    a {color: #fff;}

    :visited {
      background-color: #fff;
    }
  }

`


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  buttonBackground: {
    backgroundColor: "#4B5F70",
    borderRadius: "5px 5px 0px 0px",
    '&:hover': {
      backgroundColor: "#4B5F70"

    }
  }
}));

function Navbar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <NavbarWrapper className={classes.root}>
      <Link to="/">
        <LogoWrapper src={Logo} alt="Dialog Aktiv Logo" />
      </Link>

      <MenuWrapper>
      <Button>
          <Link to={menudata.Menu[1].slug}><b>{menudata.Menu[1].name}</b></Link>
        </Button>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={open ? classes.buttonBackground : undefined}
        >
         <p style={{margin: "0px"}}><b className="font__white">{menudata.Menu[0]}</b></p>
        </Button>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper style={{backgroundColor: "#4B5F70"}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    
                  >
                    {menudata.Submenu.map((item) => (
                      <MenuItem onClick={handleClose}  style={{borderBottom: item.name === "Coaching und Training" ? "0" : "1px solid #fff"}}>
                        <Link to={item.slug}>{item.name}</Link>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button>
          <Link to={menudata.Menu[2].slug}><b>{menudata.Menu[2].name}</b></Link>
        </Button>

        <Button>
          <a href="#kontakt" ><b>{menudata.Menu[3].name}</b></a>
        </Button>
      </MenuWrapper>
    </NavbarWrapper>
  );
}

export default Navbar;
