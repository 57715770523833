import React from "react"
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid";


const ImageBackground = styled.div`
background-image: ${props => props.url};
height: 100%;
background-position: center;
background-size: cover;
}
`

const ColorWrapper = styled.div`
height: 100%;
background-color: ${props => props.color};
display: flex;
align-items: center;
text-align: center;
 div {
  width: 100%;

 }
`

const TextWrapper = styled.div`
    p {
        letter-spacing: 8px;
        width: 70%;
        margin: 30px auto;
        @media (max-width: 600px) {
          letter-spacing: 4px;

        }
    }

    div {
        text-align: right;
        letter-spacing: 4px;
        width: 70%;
        margin: 0px auto;
        color: #fff;
        opacity: 0.5;
    }
`

const useStyles = makeStyles((theme) => ({

    herocontainer: {
      height: "80vh",
      [theme.breakpoints.down('lg')]: {
        maxHeight: "650px",
      },

      [theme.breakpoints.down('sm')]: {
        height: "100vh",
        minHeight: "650px",
        maxHeight: "850px",

      },
    },
    imagecontainer: {
      [theme.breakpoints.down('sm')]: {
        height: "60%",
      },
    },
    textcontainer: {
      [theme.breakpoints.down('sm')]: {
        height: "40%",
      },
    }

  }));


function HeroComponent(props) {
    const classes = useStyles();

  return (
    <div id="back-to-top-anchor">
    <Grid className={classes.herocontainer} container spacing={0}>
      <Grid className={classes.imagecontainer} item xs={12} sm={12} md={6}>
        <ImageBackground url={props.imageUrl} style={{backgroundPosition: props.styleProp}} />
      </Grid>

      <Grid className={classes.textcontainer} item xs={12} sm={12} md={6}>
          <ColorWrapper color={props.background}  >
              <TextWrapper>
            <h1>{props.title}</h1>
            <p className="font__white">{props.zitat}</p>
            <div>{props.autor}</div>
            </TextWrapper>
            </ColorWrapper>
      </Grid>
    </Grid>
    </div>
  );
}

export default HeroComponent;
