import React, { Component } from "react";
import '../App.css';
import styled from "styled-components"
import Layout from "../components/layout/layout"
import ContentWrapper from "../components/layout/contentWrapper"

const TextDiv = styled.div`
padding: 80px;

h1, h2, a {
  color: #606060
}

@media(max-width: 600px) {
  padding: 30px;

}
`

const HeaderDiv = styled.div`
background-color: #5D768B;
height: 30vh;
width: 100vw;
display: flex;
justify-content: center;
align-items: center;

`



class Impressum extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}
  render() {
    return (
      <Layout>
                    <HeaderDiv>
            <h1 className="uppercase">Impressum</h1>

            </HeaderDiv>
        <ContentWrapper>
          <TextDiv>

        <br/> 

        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          dialog aktiv
          <br />
          Kristina Elke Lahl
          <br />
          Dipl. Juristin
          <br />
          Uhlbacher Straße 33
          <br />
          73733 Esslingen
        </p>
        <br/> 

        <h2 >Kontakt</h2>
        <p>
        Telefon:  <a href="tel:+497113457001">+49 711 3457001</a>
          <br />
          Mobil: <a href="tel:+491717117302">+49 171 7117302</a>
          <br />
          E-Mail: <a href="mailto:info@dialog-aktiv.de"> info@dialog-aktiv.de</a>
        </p>
        <br/> 

        <h2>Umsatzsteuer-ID</h2>

        <p>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
          DE202262294
        </p>
        <br/> 

        <h2>Webdesign</h2>
        <p>Sina Rosemann Web Design, Markgröningen</p>
        <br/> 

        <h2>Fotografie</h2>
        <p>Alex Rimmele Esslingen-Berkheim</p>
        <br/> 

        <h2>Haftungshinweis:</h2>
        <p>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
          sind ausschließlich deren Betreiber verantwortlich. Wir haben darauf
          keinen Einfluss. Bei Bekanntwerden von Rechtsverletzungen entfernen
          wir derartige Links umgehend.
        </p>
        </TextDiv>
        </ContentWrapper>
        <div id="kontakt"></div>

      </Layout>
    );
  }
}

export default Impressum;
