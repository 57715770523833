import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import ButtonRed from "./layout/buttonRed"

import Grid from "@material-ui/core/Grid";

const ImageBackground = styled.div`
background-image: ${(props) => props.url};
height: 100%;
background-color: #A0040C;
background-position: left;
background-size: cover;
border: 1px solid #fff;
display: flex;
align-items: center;
justify-content: center;
}
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  ZweiSpalterContainer: {
    height: "70vh",
    minHeight: "550px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
        height: "90vh",
        minHeight: "650px",
        maxHeight: "850px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "100vh",
        minHeight: "850px",
        maxHeight: "850px",
      },
  },
  imagecontainer: {
    textAlign: "center",
    maxHeight: "600px",

    "& p": {
      margin: "0px",
      paddingBottom: "20px",
    },
    "& .author": {
        opacity: "0.5",
        textAlign: "right",
      },
      [theme.breakpoints.down("sm")]: {
        height: "55%",
      },
      [theme.breakpoints.down("xs")]: {
        height: "45%",
      },
  },
  text: {
    padding: "0px 80px",
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    "& p": {
      lineHeight: "25px"
    },
    [theme.breakpoints.down("sm")]: {
        padding: "0px 80px",
        height: "45%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0px 30px",
        height: "55%",
      },
     
  },
}));

function ZweiSpalter(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.ZweiSpalterContainer} container spacing={0}>
        <Grid className={classes.text} item xs={12} sm={12} md={6}>
          <div>
          {props.title === "„Der Weg in die Köpfe führt durch die Herzen.“" ? <h3 className="font__red uppercase">{props.title}</h3> : <h2 className="font__red uppercase">{props.title}</h2>}
            
            {props.content.split("\n").map((i, key) => {
              return <p key={key}>{i}</p>;
            })}
                     {props.title === "„Der Weg in die Köpfe führt durch die Herzen.“" && <ButtonRed link="/ueber-uns">Über Uns</ButtonRed>} 

          </div>
          <br/>
        </Grid>

        <Grid className={classes.imagecontainer} item xs={12} sm={12} md={6}>
          <ImageBackground url={props.imageUrl}>
            <div>
              {props.quote.split("\n").map((i, key) => {
                return (
                  <p className="font__white" key={key}>
                    <b>{i}</b>
                  </p>
                );
              })}
              <p className="font__white author">{props.author}</p>
            </div>
          </ImageBackground>
        </Grid>
      </Grid>
    </div>
  );
}

export default ZweiSpalter;
