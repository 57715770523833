import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { Link } from "react-router-dom";

//Icons
import PhoneIcon from "@material-ui/icons/Phone";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import EmailIcon from '@material-ui/icons/Email';

// Layout Components
import ButtonRedFooter from "./buttonRedFooter"

const HrWrapper = styled.div`
display: inline-flex;
width: 100%;
margin-top: 40px;
 hr {
   width: 50%;
   height: 1px;
   color: #fff;
 }
 span {
   padding: 0px 10px; 
 }
`

const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
padding-top: 45px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContainer: {
    backgroundColor: "#9A9A9A",
  },
  gridHalf: {
    borderRight: "1px solid #fff",
    borderTop: "1px solid #fff",
    padding: "30px", 

  },
  gridFull: {
    borderRight: "1px solid #fff",
    borderTop: "1px solid #fff",
    padding: "30px",
    '& svg': {
      [theme.breakpoints.down('sm')]: {
        height: "20px",
      },
  },
},
  flex: {
    display: "flex",
    width: "fit-content",
    color: "#fff",
  },
  blueleft: {
    backgroundColor: "#5D768B",
    borderTop: "1px solid #fff",
    textAlign: "left",
    paddingLeft: "60px",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      paddingLeft: "0px",
    },
  },
  blueright: {
    backgroundColor: "#5D768B",
    borderTop: "1px solid #fff",
    textAlign: "right",
    paddingRight: "60px",
    [theme.breakpoints.down('xs')]: {
      borderTop: "0px ",
      textAlign: "center",
      paddingRight: "0px",
    },
  },
  emailIcon: {
    paddingRight: "10px"
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container spacing={0}>
        <Grid className={classes.gridHalf} item xs={12} sm={6} md={3}>
          <p className="uppercase font__white">
            <b>dialog aktiv </b>
          </p>
          <p className="font__white">
            Kristina Lahl <br />
            Uhlbacher Straße 33 <br />
            73733 Esslingen
          </p>
          <p>
            <a href="tel:07113457001">Tel: 0711- 3457001 </a>
          </p>
          <p>
            <a href="tel:01717117302">Tel: 0171-7117302 </a>
          </p>
          <p>
            <a href="mailto:info@dialog-aktiv.de">Mail: info@dialog-aktiv.de</a>
          </p>
        </Grid>
        <Grid className={classes.gridHalf} item xs={12} sm={6} md={3}>
          <p className="uppercase font__white">
            <b>Einfach anrufen</b>
          </p>
          <a href="tel:01717117302">
            <h4 style={{ fontSize: "160%", margin: "0" }}>
              <PhoneIcon style={{marginRight: "15px"}}/>
              0171/7117302
            </h4>{" "}
          </a>
          <HrWrapper>
          <hr/><span className="font__white uppercase"><b>Oder</b></span><hr/>
          </HrWrapper>
          <ButtonWrapper>
          <ButtonRedFooter link="mailto:info@dialog-aktiv.de"><span className={classes.emailIcon} ><EmailIcon/></span>Anfrage</ButtonRedFooter>
          </ButtonWrapper>
        </Grid>
        <Grid className={classes.gridFull} item xs={12} sm={12} md={6}>
          <p className="uppercase font__white">
            <b>Entdecke</b>
          </p>

          <p>
            <Link to="/erfolgreiche-kommunikation" className={classes.flex}>
              Erfolgreiche Kommunikation
              <ArrowRightIcon />
            </Link>
          </p>
          <p>
            <Link to="/kommunikation-am-telefon" className={classes.flex}>
              Kommunikation am Telefon
              <ArrowRightIcon />
            </Link>
          </p>
          <p>
            <Link
              to="/professionelles-zeit-und-selbstmanagement"
              className={classes.flex}
            >
              Professionelles Zeit- und Selbstmanagement
              <ArrowRightIcon />
            </Link>
          </p>
          <p>
            <Link
              to="/lösungsorientiertes-konfliktmanagement"
              className={classes.flex}
            >
              Lösungsorientiertes Konfliktmanagement
              <ArrowRightIcon />
            </Link>
          </p>
          <p>
            <Link to="/spezielle-seminarthemen" className={classes.flex}>
              Spezielle Seminarthemen
              <ArrowRightIcon />
            </Link>
          </p>
          <p>
            <Link to="/coaching-und-training" className={classes.flex}>
              Coaching und Training
              <ArrowRightIcon />
            </Link>
          </p>
        </Grid>
      </Grid>


      <Grid className={classes.gridContainer} container spacing={0}>
      <Grid className={classes.blueleft} item xs={12} sm={6}>
      <p className="font__white">© 2024 dialog aktiv</p>

      </Grid>
      <Grid className={classes.blueright} item xs={12} sm={6}>

        <p className="uppercase font__white"><Link to="/impressum">Impressum</Link> / <Link to="/datenschutz">Datenschutz</Link> </p>

      </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
