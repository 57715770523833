import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
//Data
import data from "../data/ueberuns.json";
import netzwerkbackground from "../images/Ueber Uns Netzwerkpartner.jpg";

const ImageBackground = styled.div`
background-image: ${(props) => props.url};
height: 100%;
background-color: #5D768B;
background-position: center;
background-size: cover;
border: 1px solid #fff;
display: flex;
align-items: center;
justify-content: center;
}
`;

const useStyles = makeStyles((theme) => ({
  kontaktcontainer: {
    height: "50vh",
    maxHeight: "600px",
    backgroundColor: "#5D768B",
    border: "1px solid #fff",

    [theme.breakpoints.down("sm")]: {
      height: "60vh",
      minHeight: "550px",
      maxHeight: "550px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      minHeight: "700px",
      maxHeight: "700px",
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      height: " 50%",
    },
  },

  text: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    padding: "0px 80px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 80px",
      height: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 30px",
    },
  },
}));

function NetzwerkComponent(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.kontaktcontainer} container spacing={0}>
        <Grid item xs={12} sm={12} md={6} className={classes.image}>
          <ImageBackground url={`url("${netzwerkbackground}")`} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.text}>
          <div>
            <h3>Netzwerkpartner</h3>
            <br />

            {data.Netzwerkpartner.map((item) => {
              return <p className="font__white">{item}</p>;
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default NetzwerkComponent;
