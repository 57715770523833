import React, { Component } from "react";
import "../App.css";

// Styled Components
import ContentWrapper from "../components/layout/contentWrapper"

// Components
import Layout from "../components/layout/layout"
import Akkordeon from "../components/akkordeon";
import SeminarBlock from "../components/seminarBlock";
import HeroComponent from "../components/heroComponent";
import KontaktComponent from "../components/kontaktComponent";


// Data
import dataInput from "../data/telefon.json";
import backgroundImage from "../images/Kommunikation am Telefon Header.jpg";
import fragenImage from "../images/Seminar Kommunikation am Telefon.jpg";


class Telefon extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}
  render() {
    return (
       <Layout pageTitle={dataInput.Hero.title} description={dataInput.Hero.metaDescription}>
      <HeroComponent
          imageUrl={`url("${backgroundImage}")`}
          title={dataInput.Hero.title}
          zitat={dataInput.Hero.zitat}
          autor={dataInput.Hero.autor}
          background={"#A0040C"}
        />
        <ContentWrapper>
        <Akkordeon data={dataInput}/>
        <SeminarBlock
          seminare={[
            "Weitere Seminare",
            "Erfolgreiche Kommunikation",
            "Professionelles Zeit- und Selbstmanagement",
            "Lösungsorientiertes Konfliktmanagement",
            "Spezielle Seminarthemen",
            "Coaching und Training",
          ]}
          slug={[
            "",
            "erfolgreiche-kommunikation",
            "professionelles-zeit-und-selbstmanagement",
            "lösungsorientiertes-konfliktmanagement",
            "spezielle-seminarthemen",
            "coaching-und-training",
          ]}
        />
         <KontaktComponent
            imageUrl={`url("${fragenImage}")`}
            testimonial={[
              
            ]}
          />
        </ContentWrapper>
       
        </Layout>
    );
  }
}

export default Telefon;
