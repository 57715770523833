import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";

// Styled Components
import ContentWrapper from "../components/layout/contentWrapper"

// Components
import Layout from "../components/layout/layout"

import HeroComponent from "../components/heroComponent";
import KontaktComponent from "../components/kontaktComponent";
import UeberZweispalter from "../components/ueberZweispalter"
import NetzwerkComponent from "../components/netzwerkpartner"
// Data
import dataInput from "../data/ueberuns.json";
import backgroundImage from "../images/Ueber Uns Dialog Aktiv Header.jpg";
import andrea from "../images/Andrea Rantsch.jpg";
import frank from "../images/Frank Postina.jpg";
import kirsten from "../images/Kirstenschroder.jpg";

const KundenWrapper = styled.div`
padding-top: 80px;

h2 {
  text-align: center;
  padding: 0px 30px;
}
@media(max-width: 960px){
  padding-top: 30px;
}
`

const KundenListe = styled.div`
padding: 20px 80px 80px 80px;
column-count: 3;
 p:first-child {
  margin: 0;
 }

@media(max-width: 960px){
  column-count: 2;

}
@media(max-width: 600px){
  column-count: 1;
  padding: 20px 30px 30px 30px;

}
`

const PartnerWrapper = styled.div`
display: flex;
padding: 80px;
@media (max-width: 960px) {
  padding: 40px 0px;
}
@media (max-width: 600px) {
  display: block;
}

div {
  padding: 80px;
  width: 33%;
  @media (max-width: 1280px) {
    padding: 40px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }

}
img {
  display: flex;
    margin: 0px auto;

}
`
class UeberUns extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
}
  render() {
    return (
       <Layout pageTitle="Über Uns" description={dataInput.Hero.metaDescription}>
      <HeroComponent
          imageUrl={`url("${backgroundImage}")`}
          title={dataInput.Hero.title}
          zitat={dataInput.Hero.zitat}
          autor={dataInput.Hero.autor}
          background={"#5D768B"}
          styleProp={"left"}
        />
        <ContentWrapper>
        <UeberZweispalter/>
        <KundenWrapper>
        <h2 className="font__red uppercase">Kunden die uns vertrauen.</h2>
        <KundenListe>
            {dataInput.Kunden.map((item) => {
              return (<p>{item}</p>)
            })}
        </KundenListe>
        </KundenWrapper>
        <NetzwerkComponent />

        <PartnerWrapper>
        {dataInput.Partner.map((netz) => {
          return (
          <div>
          <img src={netz.name === "Andrea Ranscht" ? andrea : netz.name === "Frank Postina" ? frank : kirsten} alt={netz.name}/>
          <h4>{netz.name}</h4>
          <p><b>Schwerpunkte:</b></p>
          <ul>
          {netz.schwerpunkte.map((punkte) => {
            return (<li>{punkte}</li>)
          })}
            </ul>
            <a href={netz.website === "www.kommos.de" ? "http://www.kommos.de/" : netz.website === "www.vorsprung-tcc.de" ? "http://www.vorsprung-tcc.de" : "http://www.kirstenschroeder.com"} className="font__red" target="_blank" without rel="noopener noreferrer">{netz.website}</a>
            </div>
            )
        })}
      </PartnerWrapper>   
         <KontaktComponent
            testimonial={[
            {
              name: "Jürgen Schindler / Leiter Fachbereich Bürgerdienste/ Stadtverwaltung Ludwigsburg",
              description: "„Frau Lahl bereitet unsere neuen Mitarbeiterinnen und Mitarbeiter der Bürgerdienste Ludwigsburg in exzellenter Weise mit sehr viel Leidenschaft und Engagement auf ihre anspruchsvolle Aufgabe für den Bürgerservice vor. Hierfür gilt Ihr unsere größte Wertschätzung.“",
            },
            {
              name: "(Daniela Tiller/ Vertriebsleiterin, Käuferle GmbH & Co. KG Aichach )",
              description: "„Frau Lahl ist eine empathische Menschenkennerin und hervorragende Trainerin, die zielorientiert, strukturiert und praxisbezogen arbeitet. Sie versteht es, auch komplexe Inhalte stets praxisbezogen und kompetent zu vermitteln, geht immer  individuell auf die Bedürfnisse der Teilnehmer ein. Nützliche, alltagstaugliche Tipps und ein fundiertes  Feedback zu den einzelnen Trainingseinheiten runden die Einheiten ab.“",
            }
          ]}
          />

        </ContentWrapper>
       
        </Layout>
    );
  }
}

export default UeberUns;
