import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import styled from "styled-components";

import Plus from "./plus"

const AkkordeonWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding: 40px 0 100px 0;

  h2 {
    max-width: 400px;
    margin: 0px auto;
    text-align: center;
    @media (max-width: 1024px) {
      max-width: 300px;
      padding: 20px 0 40px 0;

  }
  }
`;

const WrapperLeft = styled.div`
  width: 50%;
  background-color: #a0040c;
  padding: 30px;
  @media (max-width: 600px) {
    width: 100%;

  }
`;

const WrapperRight = styled.div`
  width: 50%;
  background-color: #e1e1e1;
  padding: 30px;
  @media (max-width: 600px) {
    width: 100%;

  }
`;


const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },

  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "#9A9A9A",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 180,
    padding: "0px 30px",
    "&$expanded": {
      minHeight: 180,
    },
    [theme.breakpoints.down('xs')]: {
      padding: "0px 15px",
    },
  },
  expandIcon: {
    [theme.breakpoints.down('sm')]: {
      width: "20%",
    },
  },
  content: {
    justifyContent: "space-between",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
}))(MuiExpansionPanelDetails);

export default function AkkordeonTraining(props) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <AkkordeonWrapper>
      <h2 className="font__red">Wir betreuen Sie individuell</h2>
      <br />
      {props.data.Seminarthemen.map((item) => (
        <ExpansionPanel
          square
          expanded={expanded === `panel1`}
          onChange={handleChange(`panel1`)}
        >
          <ExpansionPanelSummary
            aria-controls={`panel1d-content`}
            id={`panel1d-header`}
            expandIcon={<Plus />}

          >
            <div>
              <h3>{item.title}</h3>
            </div>
            
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: 0 }}>
            <WrapperLeft>
              <h3>Training on the job</h3>
              <h3>Ziel:</h3>
              <p className="font__white">{item.training_ziel}</p>

              <br />

              <h3>Inhalte:</h3>
              <ul>
                {item.training_inhalte.map((group) => (
                  <li className="font__white">{group}</li>
                ))}
              </ul>
              <br />

              <h3>Zielgruppe:</h3>
              <ul>
                {item.training_zielgruppe.map((group) => (
                  <li className="font__white">{group}</li>
                ))}
              </ul>
            </WrapperLeft>
            <WrapperRight>
              <h3 className="font__grey">Coaching</h3>
              <h3 className="font__grey">Ziel:</h3>
              <p className="font__grey">{item.coaching_ziel}</p>

              <br />

              <h3 className="font__grey">Inhalte:</h3>
              <ul>
                {item.coaching_inhalte.map((group) => (
                  <li >{group}</li>
                ))}
              </ul>

              <br />

              <h3 className="font__grey">Zielgruppe:</h3>
              <ul>
                {item.coaching_zielgruppe.map((group) => (
                  <li >{group}</li>
                ))}
              </ul>
            </WrapperRight>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </AkkordeonWrapper>
  );
}
